@font-face {
  font-family: 'ms_sans_serif';
  src: url('../public/fonts/sovana-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal
}


.App {
  text-align: center;
}

.App-header {
  background-color: #080807;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

h1 {
  font-family: ms_sans_serif;
  color: #4635F7;

}

img {
  max-height: 15em;
}

.App-link {
  color: #61dafb;
}